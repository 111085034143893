import React from 'react'

export default function Vitesse() {
  return (
    <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4886_81852)">
        <path d="M10.7922 38.1003C10.9402 37.8017 11.1586 37.7321 11.4207 37.7331C13.9449 37.7408 16.4695 37.7408 18.9942 37.7331C19.0505 37.7331 19.1075 37.7331 19.1637 37.7331C19.4487 37.7592 19.6308 37.9805 19.6279 38.2927C19.6249 38.6048 19.4413 38.829 19.1541 38.8319C18.422 38.8397 17.6899 38.8319 16.9578 38.8319C15.0997 38.8319 13.2417 38.8319 11.3829 38.8377C11.1253 38.8377 10.9254 38.7537 10.7907 38.4579L10.7922 38.1003Z" fill="#2E71EB" />
        <path d="M32.1222 24.6896C30.4037 24.0768 28.5813 24.6074 27.3904 26.0487C27.1984 26.2804 27.0152 26.5209 26.8143 26.7417C26.6398 26.9317 26.4239 27.079 26.1833 27.1723C25.9428 27.2655 25.684 27.3023 25.427 27.2796C25.17 27.2569 24.9217 27.1754 24.7012 27.0415C24.4807 26.9075 24.294 26.7247 24.1554 26.5071C23.8822 26.0715 23.8733 25.6092 24.1554 25.1925C26.1352 22.2307 29.3227 20.534 33.0061 21.6842C33.8585 21.9505 34.6534 22.4069 35.4641 22.7959C36.2431 23.1691 36.9796 23.6532 37.7874 23.9392C39.7563 24.6322 41.5005 24.0303 42.8616 22.2732C43.1814 21.8604 43.5061 21.4655 44.0644 21.3823C44.7712 21.2754 45.3512 21.5011 45.7858 22.0693C46.1026 22.489 46.1402 22.9711 45.8521 23.4106C44.0367 26.1903 41.0115 27.9642 37.4558 27.0812C37.042 26.9822 36.8757 27.0693 36.6856 27.4227C35.7947 29.0897 34.8672 30.7409 33.9416 32.4207C34.0644 32.5029 34.1544 32.5623 34.2386 32.6237C35.4166 33.4304 36.647 34.1749 37.7518 35.0717C38.3328 35.5439 38.7813 36.2398 39.1376 36.9149C39.8682 38.2839 40.5136 39.7005 41.161 41.1131C41.5253 41.905 41.2075 42.6811 40.4255 43.0672C39.6435 43.4532 38.7842 43.2335 38.3853 42.4673C37.9408 41.612 37.5637 40.7221 37.1449 39.8529C36.8787 39.3006 36.6312 38.7314 36.2986 38.2186C36.0302 37.7841 35.6909 37.3976 35.2948 37.0753C33.9614 36.0478 32.535 35.1648 30.966 34.5273C30.7115 34.4283 30.5809 34.4639 30.3968 34.6599C28.0982 37.0951 25.3374 37.9375 22.0934 36.9901C21.5678 36.8239 21.0635 36.5967 20.5908 36.313C19.781 35.8508 19.5514 35.0321 19.9572 34.2531C20.3829 33.4364 21.2649 33.1117 22.0924 33.474C22.3567 33.5898 22.6072 33.7353 22.8745 33.8422C24.2425 34.3926 25.5056 34.1125 26.6945 33.3275C27.727 32.6455 28.4496 31.6942 29.0178 30.6122C30.0269 28.6924 31.0346 26.7727 32.041 24.8529C32.0697 24.8094 32.0885 24.7638 32.1222 24.6896Z" fill="#2E71EB" />
        <path d="M34.8832 19.7823C34.8983 18.9701 35.232 18.1964 35.8124 17.6281C36.3928 17.0597 37.1733 16.7423 37.9856 16.7443C39.6833 16.7641 41.0899 18.1925 41.0543 19.8625C41.0187 21.57 39.608 22.946 37.9232 22.9173C37.1048 22.9035 36.3251 22.5657 35.7553 21.9781C35.1854 21.3904 34.8719 20.6008 34.8832 19.7823Z" fill="#2E71EB" />
        <path d="M19.6098 31.1027H14.8425C14.7254 31.1072 14.6081 31.102 14.4917 31.0872C14.2468 31.0448 14.0785 30.849 14.108 30.5914C14.1307 30.3984 14.2813 30.2055 14.4185 30.0627C14.4967 29.9836 14.6625 30.0019 14.7887 30.0019C18.0095 30.0019 21.2301 30.0019 24.4504 30.0019C24.5672 29.9988 24.6839 30.0125 24.7979 30.0424C24.9048 30.0649 25.0004 30.1328 25.0654 30.2326C25.1305 30.3325 25.1603 30.4568 25.1487 30.5808C25.1503 30.7118 25.1073 30.8384 25.0287 30.9335C24.9501 31.0287 24.8422 31.0849 24.728 31.0901C24.5901 31.1035 24.4516 31.1077 24.3132 31.1027H19.6098Z" fill="#2E71EB" />
        <path d="M16.3088 24.4768H12.4757C12.0836 24.4768 11.8954 24.2974 11.8954 23.9299C11.8954 23.5623 12.0897 23.3733 12.475 23.3723H20.1708C20.2558 23.3709 20.3407 23.3813 20.4243 23.4032C20.626 23.461 20.739 23.652 20.7323 23.9453C20.7352 24.076 20.7021 24.2032 20.6402 24.2992C20.5783 24.3953 20.4925 24.4525 20.4014 24.4585C20.3083 24.4722 20.2146 24.4776 20.121 24.4749L16.3088 24.4768Z" fill="#2E71EB" />
        <path d="M27.3663 17.853H31.205C31.2902 17.8459 31.3757 17.8504 31.4603 17.8664C31.6468 17.9184 31.7647 18.0782 31.7782 18.3613C31.7916 18.6607 31.6845 18.8494 31.4886 18.9255C31.4051 18.9501 31.3197 18.9589 31.2347 18.9515H23.4791C23.4025 18.9572 23.3257 18.9507 23.2501 18.9322C23.048 18.8667 22.9363 18.679 22.9423 18.3854C22.9484 18.0917 23.0682 17.9117 23.271 17.8636C23.3557 17.85 23.441 17.8462 23.5262 17.852L27.3663 17.853Z" fill="#2E71EB" />
      </g>
      <defs>
        <clipPath id="clip0_4886_81852">
          <rect width="38.5687" height="26.5116" fill="white" transform="translate(7.47675 16.7443)" />
        </clipPath>
      </defs>
    </svg>
  )
}
