import React from 'react'

export default function Tir() {
  return (
    <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4886_83479)">
    <path d="M23.3741 38.7151C22.9574 38.9866 22.6574 39.6769 22.024 39.1269C21.7083 38.8523 21.8172 38.57 22.4662 37.8317L20.3495 35.6071C20.173 35.7748 20.0014 35.9424 19.823 36.1032C19.5132 36.3836 19.1582 36.3865 18.9112 36.1179C18.6641 35.8493 18.6965 35.5189 18.9769 35.2355C19.1475 35.062 19.3337 34.9041 19.5298 34.7247L17.2444 32.5501C16.9405 32.7746 16.616 33.3453 16.0601 32.8168C15.766 32.5364 15.8689 32.2374 16.417 31.6187L15.3218 30.5648C15.0344 30.717 14.7377 30.8513 14.4336 30.9667C14.3355 30.9981 14.159 30.8736 14.0629 30.7755C13.7776 30.4814 13.86 30.2285 14.4659 29.5343C14.2855 29.3716 14.0875 29.2127 13.912 29.0323C13.1119 28.2068 13.1835 27.0597 14.0786 26.3362C14.3728 26.0979 14.6767 25.8714 15.0022 25.6204C15.1512 25.7724 15.2963 25.9146 15.4346 26.0636C18.4935 29.2814 21.5378 32.5168 24.6173 35.7101C26.2948 37.4513 28.1213 39.0229 30.3116 40.1024C30.9538 40.4191 31.6421 40.6426 32.3705 40.9348C31.7529 41.673 31.3048 42.5437 30.4214 42.9878C29.5381 43.4319 28.69 43.3172 27.9056 42.729C27.4468 42.3838 27.0105 42.0103 26.5389 41.6299C26.3801 41.7819 26.2301 41.9377 26.0673 42.0779C25.7673 42.3378 25.4261 42.3309 25.185 42.0701C24.9575 41.826 24.9624 41.4818 25.2173 41.2142C25.3554 41.0852 25.5018 40.9653 25.6556 40.8553L23.3741 38.7151Z" fill="#2E71EB"/>
    <path d="M28.3459 29.5742C27.2145 29.6184 26.1164 29.6644 25.0183 29.7046C23.6614 29.7537 22.3055 29.8027 20.9495 29.8389C20.8204 29.84 20.6956 29.792 20.6005 29.7046C19.0769 28.117 17.5585 26.5241 16.0454 24.926C16.027 24.8969 16.0115 24.8661 15.9993 24.8339C16.6092 24.3593 17.2239 23.8799 17.8406 23.4034C18.4396 22.9397 19.0455 22.4847 19.6377 22.0132C19.7317 21.9239 19.7952 21.8072 19.8191 21.6798C20.242 19.7974 20.66 17.9143 21.0731 16.0306C21.0868 15.9708 21.1025 15.9119 21.1191 15.8531C21.2299 15.4786 21.527 15.2815 21.8554 15.3629C22.1839 15.4443 22.378 15.7551 22.3015 16.1316C22.1839 16.7198 22.0486 17.3012 21.9202 17.8855C21.579 19.4474 21.2338 21.0092 20.9015 22.574C20.8695 22.7108 20.8869 22.8545 20.9505 22.9799C21.4799 23.8162 22.0074 24.6544 22.5731 25.4653C22.9433 26.0053 23.4923 26.3974 24.1234 26.5723C24.7544 26.7471 25.4269 26.6936 26.0223 26.4212C26.2244 26.3242 26.3876 26.1614 26.485 25.9594C27.6214 23.3259 28.74 20.6837 29.8597 18.0434C29.893 17.965 29.9205 17.8836 29.9577 17.8071C30.1273 17.4816 30.4421 17.3532 30.7528 17.4796C31.0636 17.6061 31.198 17.9326 31.0764 18.2728C30.994 18.5032 30.8921 18.7258 30.797 18.9503C29.7479 21.422 28.693 23.8917 27.6596 26.3692C27.5785 26.589 27.5655 26.8282 27.6223 27.0555C27.8047 27.7634 28.0331 28.4605 28.241 29.1624C28.2802 29.2968 28.3077 29.4252 28.3459 29.5742Z" fill="#2E71EB"/>
    <path d="M38.8096 32.643C38.4106 32.6097 37.9086 32.5921 37.4155 32.5146C37.2498 32.4881 37.0762 32.3332 36.9615 32.192C36.4252 31.5401 36.2556 30.7371 36.0399 29.9459C35.8752 29.3419 36.0399 28.9007 36.4674 28.4752C37.1223 27.8291 37.8567 27.3262 38.7312 27.0369C38.8525 26.9893 38.9861 26.9824 39.1116 27.0173C40.0646 27.4252 40.9274 27.9625 41.5931 28.7821C41.7892 29.0262 41.8931 29.2723 41.7892 29.5841C41.5617 30.3018 41.3852 31.0371 41.1186 31.741C40.9048 32.3009 40.4852 32.645 39.8224 32.6009C39.5224 32.5754 39.2155 32.6215 38.8096 32.643Z" fill="#2E71EB"/>
    <path d="M24.924 34.2539H26.5329C27.5976 34.2539 28.6614 34.2608 29.7261 34.249C29.8267 34.241 29.9274 34.2618 30.0165 34.309C30.1056 34.3563 30.1794 34.428 30.2291 34.5157C30.6399 35.104 31.0762 35.6815 31.5233 36.2462C32.1282 37.0129 32.6655 37.8149 32.7762 38.811C32.7942 39.1244 32.7958 39.4386 32.7812 39.7522C29.5536 38.7551 27.1819 36.6874 24.924 34.2539Z" fill="#2E71EB"/>
    <path d="M38.3882 25.5051C38.5352 25.9482 38.2734 26.1786 37.7999 26.3875C36.8606 26.8002 36.0832 27.4542 35.4047 28.2268C35.3507 28.2714 35.2878 28.3042 35.2202 28.323C35.1526 28.3417 35.0819 28.346 35.0125 28.3356C34.3096 28.2297 33.8507 27.9434 33.8458 27.0963C33.8458 26.5816 33.5576 26.0718 33.4252 25.5531C33.4081 25.4841 33.4051 25.4123 33.4164 25.342C33.4277 25.2718 33.453 25.2045 33.4909 25.1443C34.1167 24.3882 34.9018 23.7795 35.79 23.3619C35.9949 23.2638 36.1243 23.307 36.3096 23.4344C36.8443 23.8167 37.4038 24.1633 37.9842 24.4717C38.2931 24.6296 38.4411 24.7855 38.3872 25.1247C38.3792 25.2514 38.3796 25.3784 38.3882 25.5051Z" fill="#2E71EB"/>
    <path d="M39.4039 25.2773C39.2705 24.8342 39.5019 24.5802 39.998 24.3636C40.5372 24.1253 41.0167 23.7439 41.5039 23.3998C41.6657 23.2861 41.7765 23.2527 41.9598 23.339C42.7957 23.7214 43.5411 24.2764 44.1472 24.9675C44.1842 25.0277 44.2088 25.0948 44.2196 25.1646C44.2303 25.2345 44.2271 25.3058 44.2099 25.3744C44.0387 26.1319 43.8531 26.8869 43.653 27.6392C43.6227 27.7568 43.504 27.851 43.4246 27.9549C43.4187 27.9627 43.4011 27.9608 43.3903 27.9657C42.7746 28.302 42.3275 28.2059 41.801 27.6607C41.2559 27.0941 40.5206 26.696 39.8264 26.295C39.5245 26.1205 39.3362 25.9832 39.4019 25.6205C39.4108 25.5063 39.4115 25.3916 39.4039 25.2773Z" fill="#2E71EB"/>
    <path d="M44.2188 33.5034C43.6884 33.577 43.155 33.6329 42.6305 33.7338C42.4197 33.774 42.3187 33.7338 42.1707 33.578C41.6952 33.0877 41.5902 32.6515 42.0216 32.0161C42.5226 31.2779 42.7148 30.3837 42.7874 29.4866C42.804 29.2827 42.8913 29.217 43.0668 29.1288C43.6639 28.8288 44.1021 28.8935 44.5462 29.4562C44.8698 29.8651 45.3776 30.1249 45.7865 30.47C45.8392 30.5159 45.882 30.5721 45.9122 30.6352C45.9424 30.6982 45.9593 30.7668 45.962 30.8367C45.8786 31.6593 45.5894 32.4181 45.2188 33.1544C45.0619 33.4652 44.8776 33.6348 44.5168 33.5554C44.4191 33.5449 44.3205 33.5449 44.2227 33.5554L44.2188 33.5034Z" fill="#2E71EB"/>
    <path d="M29.4261 33.0348C27.574 33.0348 25.7534 33.0348 23.9356 33.0348C23.841 33.0371 23.7487 33.0051 23.6758 32.9446C23.0876 32.3407 22.514 31.7279 21.8777 31.0573C22.0807 31.0396 22.2169 31.021 22.3532 31.0161C24.3925 30.9436 26.4318 30.869 28.4721 30.8092C28.5353 30.8157 28.596 30.8375 28.649 30.8727C28.7019 30.9079 28.7455 30.9554 28.776 31.0112C29.0015 31.6622 29.2015 32.326 29.4261 33.0348Z" fill="#2E71EB"/>
    <path d="M39.0823 36.9957C39.0212 36.9992 38.96 36.9992 38.8989 36.9957C38.547 36.9565 38.1293 37.0163 37.8646 36.8428C37.6362 36.6938 37.5881 36.2741 37.4518 35.979C37.2469 35.5359 37.0518 35.0878 36.8195 34.6603C36.7214 34.4819 36.7479 34.3819 36.8342 34.2094C37.1283 33.6211 37.5479 33.5054 38.193 33.5711C38.9862 33.6515 39.8009 33.5593 40.6029 33.4985C40.8882 33.477 41.052 33.5221 41.251 33.7486C41.6432 34.2005 41.7206 34.576 41.3549 35.1104C41.0735 35.5212 40.9559 36.0486 40.7951 36.534C40.7294 36.73 40.6471 36.8418 40.4304 36.8634C39.9813 36.9095 39.5343 36.9751 39.0872 37.033L39.0823 36.9957Z" fill="#2E71EB"/>
    <path d="M35.4713 33.5806C34.6585 33.5649 33.8458 33.5541 33.033 33.5257C32.983 33.5176 32.9351 33.4994 32.8925 33.4721C32.8498 33.4447 32.8132 33.4089 32.7849 33.3669C32.3017 32.6002 31.9931 31.7366 31.881 30.8373C31.8832 30.7765 31.8977 30.7167 31.9237 30.6616C31.9496 30.6065 31.9865 30.5572 32.032 30.5167C32.5281 30.0677 33.0369 29.6344 33.5399 29.1912C33.5761 29.1598 33.6154 29.0843 33.6379 29.0932C34.026 29.1754 34.4096 29.2778 34.787 29.4C34.885 29.4334 34.9487 29.6157 34.9831 29.7422C35.186 30.4285 35.3566 31.1276 35.5782 31.8099C35.6674 32.0825 35.8596 32.3207 36.0008 32.5766C36.0674 32.6972 36.2086 32.8708 36.1704 32.9394C36.0024 33.2056 35.8139 33.4584 35.6066 33.6953C35.588 33.7188 35.5145 33.6953 35.4664 33.6953L35.4713 33.5806Z" fill="#2E71EB"/>
    <path d="M46.1414 29.869C45.6424 29.4298 45.1325 29.0013 44.6472 28.5464C44.4139 28.3287 44.3531 28.0366 44.3913 27.7062C44.4746 26.9874 44.6601 26.2841 44.9423 25.6178C45.7855 26.8455 46.2399 28.2983 46.2463 29.7876L46.1414 29.869Z" fill="#2E71EB"/>
    <path d="M33.2303 34.261C33.9774 34.3453 34.7362 34.3737 35.4637 34.5414C35.7382 34.6051 36.0069 34.9532 36.1569 35.2365C36.4069 35.7051 36.5589 36.2258 36.7912 36.8277C35.3741 36.4107 34.1176 35.5718 33.1891 34.4227L33.2303 34.261Z" fill="#2E71EB"/>
    <path d="M41.051 22.9079C40.4039 23.2648 39.7765 23.6589 39.1 23.958C38.8911 24.0501 38.5342 23.9492 38.3029 23.8266C37.7676 23.5433 37.2675 23.1913 36.6675 22.8138C38.0752 22.367 39.5845 22.3516 41.001 22.7697L41.051 22.9079Z" fill="#2E71EB"/>
    <path d="M31.5526 29.7703C31.5315 28.3728 31.9608 27.0057 32.7772 25.8712C32.9301 26.5192 33.1017 27.1644 33.2282 27.8183C33.2603 27.9754 33.2499 28.1383 33.1982 28.2901C33.1466 28.4419 33.0554 28.5772 32.9341 28.6821C32.5066 29.0703 32.0817 29.4602 31.6595 29.8517L31.5526 29.7703Z" fill="#2E71EB"/>
    <path d="M44.6129 34.4005C43.754 35.4731 42.6716 36.2369 41.299 36.7399C41.5441 36.0388 41.6912 35.3673 42.1334 34.8349C42.2841 34.6645 42.4867 34.5483 42.7099 34.5045C43.3285 34.3986 43.956 34.3466 44.5796 34.2731L44.6129 34.4005Z" fill="#2E71EB"/>
    </g>
    <defs>
    <clipPath id="clip0_4886_83479">
    <rect width="32.8885" height="27.907" fill="white" transform="translate(13.3558 15.3488)"/>
    </clipPath>
    </defs>
    </svg>
  )
}
