import React from 'react'

export default function Vitesse() {
  return (
    <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4886_83221)">
    <path d="M33.3298 37.5643C33.5609 36.5565 33.7831 35.5804 34.0142 34.5686L35.3663 35.018C35.3385 34.5458 35.3117 34.1103 35.2859 33.6748C35.2859 33.65 35.279 33.6252 35.276 33.6004C35.2324 33.2592 35.3077 32.7533 35.1173 32.6144C34.8197 32.4022 34.3475 32.4438 33.9041 32.3694C33.8277 32.9477 33.7871 33.4983 33.6789 34.0369C33.4448 35.2104 33.2058 36.3849 32.9121 37.5455C32.6681 38.5097 31.944 38.9164 30.9659 38.7358C28.9979 38.3761 27.0285 38.0183 25.0578 37.6625C24.2841 37.5227 23.7682 37.0723 23.6522 36.4256C23.5302 35.7639 23.7861 35.1033 24.3723 34.8146C24.6869 34.6509 25.0421 34.582 25.3951 34.6162C26.8721 34.8444 28.3402 35.133 29.8113 35.3979C29.9561 35.4247 30.1009 35.4435 30.3152 35.4772C30.4461 34.7055 30.6009 33.9605 30.6951 33.2076C31.0473 30.3419 30.9489 27.4387 30.4035 24.6033C30.3631 24.4022 30.2608 24.2187 30.1108 24.0786C28.6963 22.8416 27.007 22.176 25.1977 21.7772C23.937 21.5165 22.6506 21.402 21.3637 21.436C20.5404 21.4459 20.0355 20.8041 20.3102 20.1107C20.3722 19.9266 20.4907 19.7666 20.6489 19.6537C20.807 19.5408 20.9968 19.4807 21.1911 19.4818C24.7533 19.3995 28.0982 20.0879 31.0234 22.2643C31.1197 22.3312 31.2381 22.3582 31.3538 22.3397C32.4578 22.0718 33.5589 21.7951 34.657 21.5025C34.7287 21.4806 34.7954 21.4447 34.8532 21.3969C34.9109 21.349 34.9585 21.2902 34.9933 21.2237C36.2213 18.3173 36.3205 15.4703 34.4973 12.7414C34.1571 12.2306 34.2642 11.6503 34.7245 11.3329C35.1847 11.0154 35.7769 11.1394 36.138 11.6681C37.8055 14.1153 38.2201 16.7936 37.5654 19.6396C37.3502 20.571 36.9484 21.4588 36.6449 22.3704C36.5813 22.5373 36.5629 22.7179 36.5913 22.8942C37.4369 26.3023 38.018 29.7706 38.3293 33.2681C38.4384 34.5488 38.4701 35.8373 38.4909 37.1229C38.5108 38.2815 37.5198 38.9928 36.4177 38.6347C35.4317 38.3142 34.4556 37.9601 33.4756 37.6209C33.43 37.605 33.3853 37.5861 33.3298 37.5643Z" fill="#2E71EB"/>
    <path d="M31.8399 21.35C31.4771 21.3512 31.1178 21.2807 30.7824 21.1426C30.447 21.0045 30.1422 20.8015 29.8855 20.5452C29.6288 20.289 29.4253 19.9846 29.2866 19.6494C29.1478 19.3143 29.0767 18.9551 29.0772 18.5924C29.0776 18.2297 29.1495 17.8708 29.2887 17.536C29.428 17.2012 29.6319 16.8971 29.8887 16.6412C30.1456 16.3852 30.4504 16.1824 30.7857 16.0444C31.121 15.9063 31.4802 15.8358 31.8428 15.8367C32.2055 15.8358 32.5648 15.9065 32.9 16.0449C33.2352 16.1832 33.5398 16.3865 33.7962 16.6429C34.0527 16.8994 34.2559 17.2039 34.3943 17.5392C34.5326 17.8744 34.6034 18.2337 34.6025 18.5963C34.6034 18.959 34.5325 19.3182 34.3939 19.6533C34.2553 19.9884 34.0517 20.2927 33.7948 20.5487C33.538 20.8047 33.233 21.0073 32.8975 21.1448C32.5619 21.2824 32.2025 21.3521 31.8399 21.35Z" fill="#2E71EB"/>
    <path d="M28.7371 34.8304C28.8026 33.9376 29.4801 33.3087 30.3004 33.402C30.2072 33.9446 30.1149 34.4862 30.0157 35.0635L28.7371 34.8304Z" fill="#2E71EB"/>
    <path d="M31.204 39.7441H30.9064V42.9799H31.204V39.7441Z" fill="#2E71EB"/>
    <path d="M32.9039 41.551H32.6063V47.4413H32.9039V41.551Z" fill="#2E71EB"/>
    <path d="M35.014 40.533H34.7164V45.7804H35.014V40.533Z" fill="#2E71EB"/>
    </g>
    <defs>
    <clipPath id="clip0_4886_83221">
    <rect width="18.2571" height="36.2791" fill="white" transform="translate(20.2349 11.1626)"/>
    </clipPath>
    </defs>
    </svg>
  )
}
