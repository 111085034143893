import React from 'react'

export default function Jonglage() {
  return (
    <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_4886_83509)">
<path d="M28.2588 12.5581C28.4335 12.6053 28.6135 12.6455 28.7891 12.6997C30.3828 13.1592 31.4164 14.7678 31.1919 16.4366C31.0786 17.2486 30.6758 17.9923 30.0577 18.5309C29.4397 19.0695 28.6478 19.3668 27.828 19.368C27.0141 19.3739 26.2254 19.0866 25.606 18.5585C24.9867 18.0305 24.5782 17.2971 24.4554 16.4925C24.3166 15.622 24.5216 14.7317 25.027 14.0095C25.5324 13.2873 26.2986 12.7897 27.164 12.6219C27.2237 12.6051 27.2822 12.5837 27.3387 12.5581H28.2588Z" fill="#2E71EB"/>
<path d="M25.8333 22.4654C25.5152 21.9411 25.1815 21.4265 24.8826 20.8926C24.3733 19.9805 23.2225 19.7568 22.4641 20.4479C21.9399 20.9206 21.8132 21.6537 22.1505 22.3325C22.7577 23.5558 23.5869 24.6156 24.5576 25.5697C24.6271 25.6537 24.707 25.7285 24.7953 25.7925C25.2382 26.038 25.2234 26.4094 25.1622 26.8471C25.0749 27.4911 25.0539 28.1446 25.0032 28.8244C27.2172 29.0695 29.3712 29.7005 31.3675 30.6889L29.0608 33.719C28.229 33.4569 27.4304 33.1729 26.6144 32.9501C25.5637 32.6531 24.4723 32.5262 23.3816 32.5744C22.904 32.5996 22.4252 32.5664 21.9556 32.4757C20.7062 32.2258 19.8805 31.2394 19.6909 29.8755C19.2969 27.0271 19.5634 24.2495 20.5454 21.5418C20.9526 20.4182 21.6061 19.5183 22.7507 19.0578C24.4274 18.3815 25.8577 19.25 26.0255 21.0508C26.0674 21.4999 26.0316 21.956 26.0316 22.4086L25.8333 22.4654Z" fill="#2E71EB"/>
<path d="M29.6052 34.0047L31.904 30.9851C31.9913 31.02 32.0673 31.0463 32.1399 31.0856C34.4718 32.349 36.498 34.0003 38.2813 35.954C38.6211 36.317 38.8028 36.8001 38.7864 37.2971C38.77 37.7941 38.5569 38.2642 38.1939 38.604C38.0154 38.7774 37.804 38.9133 37.5721 39.0039C37.3403 39.0944 37.0927 39.1377 36.8439 39.1313C36.5951 39.1249 36.3501 39.0688 36.1233 38.9664C35.8964 38.864 35.6923 38.7173 35.5229 38.535C34.2377 37.1737 32.8511 35.9391 31.2556 34.9527C30.7332 34.6251 30.1844 34.335 29.6052 34.0047Z" fill="#2E71EB"/>
<path d="M32.8554 29.0568C31.6968 28.9274 30.558 28.6589 29.4636 28.2573C26.6213 27.1992 24.3759 25.4054 22.7883 22.8096C22.6524 22.5864 22.5299 22.3553 22.4213 22.1176C22.133 21.4894 22.3689 20.7983 22.9639 20.5021C23.1055 20.4301 23.2599 20.3869 23.4183 20.3751C23.5767 20.3632 23.7358 20.383 23.8865 20.4331C24.0372 20.4833 24.1764 20.5628 24.2961 20.6672C24.4158 20.7716 24.5136 20.8987 24.5838 21.0412C25.6559 23.1276 27.3046 24.6051 29.3998 25.6064C30.5164 26.1262 31.7069 26.4695 32.9288 26.6243C33.5797 26.7117 34.0018 27.1398 34.0647 27.7444C34.0839 28.0127 34.0133 28.2797 33.8642 28.5035C33.715 28.7273 33.4957 28.8952 33.2407 28.9808C33.1143 29.0152 32.9855 29.0406 32.8554 29.0568Z" fill="#2E71EB"/>
<path d="M22.0213 38.1241L25.8386 38.4928C25.6761 39.0764 25.5476 39.6243 25.3703 40.1529C24.7097 42.1231 23.6333 43.8514 22.2545 45.3952C22.0916 45.5833 21.8929 45.7371 21.6701 45.8478C21.4473 45.9585 21.2047 46.0239 20.9564 46.0401C20.7081 46.0564 20.4591 46.0232 20.2237 45.9425C19.9884 45.8618 19.7714 45.7352 19.5853 45.57C19.3974 45.407 19.2436 45.2083 19.133 44.9855C19.0224 44.7626 18.957 44.5201 18.9408 44.2718C18.9245 44.0236 18.9577 43.7746 19.0383 43.5392C19.119 43.3039 19.2455 43.0869 19.4105 42.9007C20.459 41.6836 21.3179 40.3608 21.8072 38.8187C21.8806 38.6055 21.9426 38.387 22.0213 38.1241Z" fill="#2E71EB"/>
<path d="M42.5425 28.7947C42.5441 29.5988 42.2264 30.3706 41.6592 30.9406C41.092 31.5106 40.3217 31.8321 39.5176 31.8344C38.7189 31.8312 37.9536 31.5132 37.3878 30.9493C36.822 30.3855 36.5014 29.6214 36.4954 28.8226C36.4922 28.4246 36.5675 28.0299 36.717 27.6611C36.8665 27.2923 37.0873 26.9565 37.3667 26.6731C37.6461 26.3897 37.9787 26.1642 38.3454 26.0095C38.7121 25.8548 39.1057 25.7739 39.5037 25.7715C39.9019 25.7697 40.2966 25.8465 40.6651 25.9976C41.0336 26.1487 41.3686 26.3711 41.651 26.6519C41.9333 26.9328 42.1574 27.2667 42.3104 27.6344C42.4634 28.0021 42.5423 28.3964 42.5425 28.7947Z" fill="#2E71EB"/>
<path d="M25.8752 37.881L22.1496 37.5228C22.3759 35.9649 22.2868 34.4481 21.7922 32.9392C23.0303 33.0135 24.2317 33.086 25.4322 33.1541C25.6865 33.1681 25.7983 33.2712 25.8464 33.5386C26.0803 34.8599 26.1209 36.2082 25.9669 37.5411C25.9582 37.6163 25.9468 37.6914 25.9311 37.7666C25.9161 37.8064 25.8974 37.8447 25.8752 37.881Z" fill="#2E71EB"/>
</g>
<defs>
<clipPath id="clip0_4886_83509">
<rect width="23.603" height="33.4884" fill="white" transform="translate(18.9395 12.5581)"/>
</clipPath>
</defs>
</svg>
  )
}
